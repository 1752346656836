import React from 'react'
import { Container, Row, Col,  Stack } from 'react-bootstrap'
import logo from "../../Assets/images/LoGoBix4.webp"
import { Envelope, GithubLogo,  LinkedinLogo } from '@phosphor-icons/react'
import { Link } from 'react-router-dom'



function Footer_pr() {

    const buttonLink = (link) => {
        window.location.href = link

    }

  return (
    <>
     <Container  className=''>
        <Row className='anLogoRow text-center'>
        <img src={logo} className='anLogo' alt='logo'/>
        </Row>
        <Row className='footerPara'>
        <Container>A small, reliable and complete platform that serves your need 24*7 for any product, BixWieeee Now!</Container>
        </Row>
        <Row className='pt-5'>
            <Col>
            <Link to="/" className='anchorFooter'>Home</Link>
            </Col>
            <Col>
            <Link to="/wishlist" className='anchorFooter'>Wishlist</Link>

            </Col>
            <Col>
            <Link to="/cart" className='anchorFooter'>Cart</Link>

            </Col>
            <Col>
            <a href='http://another.infinityfreeapp.com/About-me'  className='anchorFooter'>About-Me</a>
            </Col>
            <Col>
            <a href='mailto:jishanarzoo@outlook.com'  className='anchorFooter'>Contact-Me</a>
            </Col>
        </Row>
       
        <Row className='mt-5 pt-4'>
            <h6>Developed by <a href='https://linkedin.com/in/jishan-arzoo-37788a25b' className='coder'>Jishan Arzoo</a></h6>
        </Row>
        <Row className='text-center pt-2'>
            <Stack direction='horizontal' gap={4} style={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                
                <GithubLogo size={32} className='footerLogos' onClick={() => buttonLink("https://github.com/JishanArzoo")} />
                <LinkedinLogo size={32} className='footerLogos' onClick={() => buttonLink("https://linkedin.com/in/jishan-arzoo-37788a25b")} />
                <Envelope size={32} className='footerLogos'  onClick={() => buttonLink("mailto:jishanarzoo@outlook.com")}/>

            </Stack>
        </Row>
    </Container> 
    </>
  )
}

export default Footer_pr




