import React from 'react'
import { Row,  Col, Container } from 'react-bootstrap'
import { PRODUCTS } from '../../products'
import Product from './Product'

function ProductList() {
  return (
    <>
     <Container fluid>
        <Row className='HeadingRow'>
            <Col className='zeropadcol text-center pt-2 pb-2'>
            <h1 className='HeadingHome'>Our Products</h1>
            </Col>
        </Row>
        <Row className='productCardRow'>
 
 {PRODUCTS.map((product) => (
    <Product data={product}  key={product.id}/>
 ))}
 

        </Row>
        </Container> 
    </>
  )
}

export default ProductList
