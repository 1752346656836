import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS } from "../../products";

function getWishlist() {
    let wishlist = {}
    for (let i = 0; i < PRODUCTS.length +1; i++){
      wishlist[i] = false;
    }
    return wishlist;
  }

const wishlistSlice = createSlice({
    name: "wishlist",
    initialState: getWishlist,
    reducers: {
        wishlistAddRemove: (state, action) => (
            {...state, [action.payload]: !state[action.payload]}
        ),
    }
})

export default wishlistSlice.reducer;
export const {wishlistAddRemove} = wishlistSlice.actions;