import { createSlice } from "@reduxjs/toolkit";
import { PRODUCTS } from "../../products";

function getCart() {
    let cart = {}
    for ( let i = 0 ; i < PRODUCTS.length + 1 ; i++){
        cart[i] = 0;
    }    
  return cart;
};

const cartSlice =  createSlice({
    name: "cart",
    initialState: getCart,
    reducers: {
        addToCart: (state, action) => (
            {...state, [action.payload]: state[action.payload] + 1}
            ),
        removeFromCart: (state, action) => (
            {...state, [action.payload]: state[action.payload] - 1}
        )
    }
})


export default cartSlice.reducer;
export const {addToCart, removeFromCart} = cartSlice.actions;