import { configureStore } from "@reduxjs/toolkit";
import cartSlice from "../features/cart/cartSlice";
import wishlistSlice from "../features/wishlist/wishlistSlice";
import wishlistCountSlice from "../features/wishlist/wishlistCountSlice";
import cartCountSlice from "../features/cart/cartCountSlice";

const store = configureStore({
    reducer: {
        cart: cartSlice,
        wishList: wishlistSlice,
        wishCounti: wishlistCountSlice,
        cartCount: cartCountSlice
    }
    
})

export default store;