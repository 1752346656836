import { createSlice } from "@reduxjs/toolkit";
 
const initialState = {
    totalWishCount: 0
}

const wishlistCountSlice = createSlice({
    name: "wishlistCount",
    initialState,
    reducers: {
        wishlistCountDecrement: (state) => {
                    state.totalWishCount--
        },
        wishlistCountIncrement: (state) => {
            state.totalWishCount++
        }
    },
    
})

export default wishlistCountSlice.reducer;
export const {wishlistCountDecrement, wishlistCountIncrement} = wishlistCountSlice.actions;

