import React from 'react'
import { useState } from 'react'
import { Carousel, Container, Row, Col, Card } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import ProductList from './ProductList'
import Footer_pr from './Footer_pr'
import Honor90Pro from "../../Assets/images/Honor90PRO_Banner.webp"
import HonorX9B from "../../Assets/images/honorX9BBanner.webp"
import Pulse from "../../Assets/images/Pulse.webp"

function Home() {
  const [index, setIndex] = useState(0)

    const handleSelect = (selectedIndex) => {
            setIndex(selectedIndex)
    }

    const navigate = useNavigate()
    const caraRedirect = (proId) => {
      navigate(`/product/${proId}`)
    }

  return (
    <>
    <Container fluid className='carouselContainer' >

      <Row>
        <Col className='zeropadcol'>
        <Carousel className='cara' activeIndex={index} onSelect={handleSelect} data-bs-theme="dark" touch controls={false}>
        <Carousel.Item>
          <img src="images/Honor90PRO_Banner.webp" className='caraImg' onClick={() => caraRedirect(2)}  alt='Honor90Pro' fetchPriority='high'/>
        </Carousel.Item>

        <Carousel.Item>
          <img src={HonorX9B} className='caraImg' onClick={() => caraRedirect(3)} alt='HonorX9B' />
        </Carousel.Item>

        <Carousel.Item>
          <img src={Pulse} className='caraImg' onClick={() => caraRedirect(0)}  alt='OnePlisi'/>
        </Carousel.Item>
      </Carousel>  
        </Col>


      </Row>

      <Row>
      <Col className='zeropadcol'>
      
<ProductList />

      </Col>
      </Row>

      <Row className='mt-5 footerParentColRow'>
        <Col className='zeropadcol footerParentCol text-center'>
        <Footer_pr/>
        </Col>
      </Row>
    </Container>
   
     
    
     
    </>
  )
}

export default Home
