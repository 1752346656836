import React from 'react'
import { Col, Container, Card, Button } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import { addToCart} from '../../features/cart/cartSlice'
import { useDispatch, useSelector } from 'react-redux'
import { increaseCartCount } from '../../features/cart/cartCountSlice'

function Product(props) {
  const { id ,productName, price, productImage} = props.data
  const cartItems = useSelector((state) => state.cart)
    const dispatch = useDispatch()


    const addToCartHandler = (id) => {
      dispatch(addToCart(id))
      dispatch(increaseCartCount())
    }

    const navigate = useNavigate()
    const ProdiPage = (stati) => {
        navigate(`/product/${stati}`)
    }

    const productCount = cartItems[id]
  return (
    
      <Col className='zeropadcol pt-4' xs={6} sm={{span: "3", offset: "0"}}>
           <Container>
           <Card className='productCard' >
                <Card.Img variant='top' src={productImage} className='cardImg' onClick={() => ProdiPage(id)} alt={productName} fetchPriority='high'  />
                <Card.Body className='cardBody'>
                  <Card.Title className='productTitli' onClick={() => ProdiPage(id)}>{productName}</Card.Title>
                  <Card.Text>Rs. {price}</Card.Text>
                  
                  
                </Card.Body>
                <Card.Footer style={{background: "pink"}}>
                  <div className='footerCard'>
                  <Button variant='light' onClick={() => addToCartHandler(id)} className='cardButton'>
                    Add to Cart {productCount > 0 && <> ({productCount}) </>}
                    </Button>
                  </div>
                  
                  </Card.Footer>

            </Card>
           </Container>
            </Col>
    
  )
}

export default Product
