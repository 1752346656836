import { lazy, useEffect, Suspense } from 'react';
import './Assets/css/App.css';
import "./Assets/css/responsive.css"
import AllRoutes from './AllRoutes';
import { BrowserRouter as Router, Routes, Route,  useLocation } from 'react-router-dom';
import NavBar_pr from './RU_components/NavBar_pr';
import { Col, Container, Row } from 'react-bootstrap';
import Home from './pages/Home/Home';
// import Cart from './pages/Cart/Cart';
// import ProductDetails from './RU_components/ProductDetails';
// import Wishlist from './pages/Wishi/Wishlist';
// const Cart = lazy(() => import('./pages/Cart/Cart'))
// const ProductDetails = lazy(() => import('./RU_components/ProductDetails'))
// const Wishlist = lazy(() => import('./pages/Wishi/Wishlist'))


function App() {
  // const {pathname} = useLocation();
  // useEffect(() => {
  //   window.scrollTo(0,0);
  // },[pathname])

 
  
  return (
    <Router>
      <Container fluid>

        <Row className='mb-5 pb-2 pb-sm-3'>
          <Col className='zeropadcol'>
            <NavBar_pr />
          </Col>
        </Row>

        <Row>
          <Col className='zeropadcol'>
          <Suspense fallback={<div style={{display: "flex", justifyContent: 'center', alignItems: "center", color: "pink"}}>Loading...</div>}>

            {/* <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/wishlist' element={<Wishlist />} />
              <Route path="/product/:id" Component={ProductDetails} />
            </Routes> */}
            <AllRoutes />

          </Suspense>
          </Col>
        </Row>
      </Container>
    </Router>  
  );
}


export default App;