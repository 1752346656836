import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    totalCartCount: 0
}

const cartCountSlice = createSlice({
    name: "cartCount",
    initialState,
    reducers: {
        increaseCartCount: (state) => {
            state.totalCartCount++
        },
        decreaseCartCount: (state) => {
            state.totalCartCount--
        }
    }
})

export default cartCountSlice.reducer;
export const {increaseCartCount, decreaseCartCount} = cartCountSlice.actions;