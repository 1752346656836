import product1 from "./Assets/images/OnePlus.webp"
import product2 from "./Assets/images/OneplusNord.webp"
import product3 from "./Assets/images/Honor90Pro.webp"
import product4 from "./Assets/images/HonorX9B.webp"
import product5 from "./Assets/images/victus.webp"
import product6 from "./Assets/images/HonorChoiceWatch.webp"
import product7 from "./Assets/images/Chair2.webp"
import product8 from "./Assets/images/Chair.webp"
import product9 from "./Assets/images/BritanniaRollYo.webp"
import product10 from "./Assets/images/MaggieVEGATTA.webp"
import product11 from "./Assets/images/JimJam.webp"
import product12 from "./Assets/images/HersheyKisses.webp"

export const PRODUCTS = [
    {
        id: 0,
        productName: "OnePlus 20 Pro",
        price: 40000,
        productImage: product1,
        description:"Pristine 2K Display with Aqua Touch: - 2K 120 Hz ProXDR Display with advanced LTPO for brighter, more vibrant visuals - Eye Care certified by TÜV Rheinland - Aqua Touch helps you stay swiping, even with wet hands"
    },
    {
        id: 1,
        productName: "OnePlus Nord",
        price: 18999,
        productImage: product2,
        description:"Rear Camera Mode: Hi-res 108MP mode, 3x Lossles Zoom, Photo, Video, Nightscape, Expert, Panoramic, Portrait, Macro, Time-lapse, Slow-motion, Long exposure, Dual-view video, Text Scanner, 1080p/720p@30fps, Video zoom: 1080P@30fps, 720P@30fps, Slow motion: 720P@120fps, Time-Lapse: 1080P@30fps, Steady Video EIS support"
    },
    {
        id: 2,
        productName: "Honor 90 Pro",
        price: 27000,
        productImage: product3,
        description:"200MP Ultra-Clear Rear Camera, Master of Photography: HONOR 90 features a 200MP Ultra-Clear Main Camera with the largest 1/1.4-inch sensor in the segment, a 12MP Ultra Wide and Macro 2-in-1 Camera, and a 2MP Depth Camera and a 50MP Selfie camera and 100° wide FOV hardware to capture exquisite photos and videos."
    },
    {
        id: 3,
        productName: "Honor X9B 5G",
        price: 21999,
        productImage: product4,
        description:"High Power Experience - The HONOR X9b delivers a robust Android 13 experience with MagicOS 7.2, powered by a Qualcomm Snapdragon 6 Gen 1 (4nm) chipset for efficient multitasking and powerful performance. Plus, enjoy exceptional gaming with the Adreno 710 GPU."
    },
    {
        id: 4,
        productName: "HP Victus",
        price: 21999,
        productImage: product5,
        description:"Experience immersive, lifelike gameplay with stunning graphics and accelerate heavy workflows with high-speed data processing, video editing, and rendering."
    },
    {
        id: 5,
        productName: "Honor Choice Watch",
        price: 5999,
        productImage: product6,
        description:"Comes with a 1.3 TFT Color Full Touch Screen and a 240*240 Pixel High Resolution this fashion smartwatch is covered to flaunt the sleek and stylish look always with 260 NITS Peak Brightness"
    },
    {
        id: 6,
        productName: "WFH Chair",
        price: 12999,
        productImage: product7,
        description:"Patented SmartGRID Technology: Our ergonomic high back chair incorporates the patented SmartGRID Technology, which offers pressure-free support to your buttocks and cradles your tailbone. The design of the office chair enhances your natural sitting posture, ensuring optimum comfort. The SmartGRID seat evenly distributes your weight, promoting healthy spinal alignment throughout the day."
    },
    {
        id: 7,
        productName: "Gaming Chair",
        price: 6999,
        productImage: product8,
        description:"Super Lounge Dual Tilt Mechanism: Ergonomic high back office chair features a super lounge recline at 135 seat tilt for your ultimate comfort. Our dual lever mechanism lets you control seat height and backrest angle with ease. Find your perfect position in any situation with adjustable height and angles. Get ready to sit in comfort like never before"
    },
    {
        id: 8,
        productName: "Britannia Rolls",
        price: 19,
        productImage: product9,
        description:"Britannia Fudge It Chocolate Brownie Cake is in town. With a delectable, mouth-watering taste, it gonna win your heart and be your new dessert buddy. Filled with the goodness of wheat, eggs, milk, and soya, the brownie cake deliciously melt in your mouth. This soft, spongy and fluffy brownie cake definitely will make you say, One more! Believing in delivering fresh and healthy products, Britannia India manufactures some of India's favourite cakes."
    },
    {
        id: 9,
        productName: "Veg Atta Maggi",
        price: 19,
        productImage: product10,
        description:"Enjoy MAGGI NUTRI-LICIOUS Masala Veg Atta Noodles with appetizing aroma and delicious masala taste made using 20 Spices & Herbs. These masalas are roasted to perfection (refer pack for more details) for an aroma & flavour which you will love. These MAGGI NUTRI-LICIOUS Masala Veg Atta Noodles are a truly a good combination of delicious and nutritious Whole Wheat Flour (Atta), Colorful Vegetables & 20 Spices & Herbs. Also, loaded with the Goodness of whole wheat, each meal is deliciously filling. Each serve (72.5g) of the MAGGI Masala Veg Atta Noodles is a Source of Fiber & Iron (Refer pack for more details)."
    },
    {
        id: 10,
        productName: "JimJam Biscuits",
        price: 20,
        productImage: product11,
        description:"Prepared using Refined Wheat Flour (Maida), Sugar, Edible Vegetable Oil (Palm) & Interesterified Vegetable Fat, Invert Syrup, Dextrose, Milk Solids, Edible Starch, Maltodextrin, Fruit Products, Iodised Salt, Emulsifiers, Raising Agents, Gelling Agent, Acidity Regulators, Artificial Flavouring Substances (Mixed Fruit, Butter & Vanilla)"
    },
    {
        id: 11,
        productName: "Hershey KiSSes",
        price: 119,
        productImage: product12,
        description:"Enjoy melt-in-mouth chocolates that are perfect bite-sized chocolates in 5 delectable flavors. Perfect for sharing, HERSHEY’S Kisses are delicious chocolates that are individually wrapped to make your loved ones feel special. Great for sharing with friends and family; made with delicious chocolate. Suitable for vegetarians and a great choice for anyone who loves delicious, melt-in-mouth chocolates."
    },
]