import React , {useEffect, lazy} from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'
import Home from './pages/Home/Home'
const Cart = lazy(() => import('./pages/Cart/Cart'))
const ProductDetails = lazy(() => import('./RU_components/ProductDetails'))
const Wishlist = lazy(() => import('./pages/Wishi/Wishlist'))

function AllRoutes() {
    
  const {pathname} = useLocation();
  useEffect(() => {
    window.scrollTo(0,0);
  },[pathname])
  return (
    <>
        <Routes>
              <Route path='/' element={<Home />} />
              <Route path='/cart' element={<Cart />} />
              <Route path='/wishlist' element={<Wishlist />} />
              <Route path="/product/:id" Component={ProductDetails} />
        </Routes> 
    </>
  )
}

export default AllRoutes
