import React, { useContext, useEffect, useState } from 'react'
import { Badge, Container, Nav, Navbar } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { List, ShoppingCartSimple } from '@phosphor-icons/react'
import { PRODUCTS } from '../products'
import { useSelector } from 'react-redux'

function CartIconi(props) {
  const cartItems = useSelector((state) => state.cart)
  const totalCartCount = useSelector((state) => state.cartCount.totalCartCount) 
  // const [totalCartCount, setTotalCartCount] = useState(0)

  // useEffect(() => {
  //    for (let i = 0; i < PRODUCTS.length +1; i++){
  //       setTotalCartCount((prevState) => prevState + cartItems[i] )
  //   }
  // }, [cartItems])
//   const getTotalCartCount = () => {
   
// }
  // const { totalCartCount} = useContext(Contexti)
  return (
    <Nav.Link href='#' className={props.classyName} ><Link to="/cart"  className='navilinko'><ShoppingCartSimple size={32} /><Badge bg='info' className='cartBadge' style={{borderRadius: "100%"}}>{totalCartCount}</Badge></Link></Nav.Link>
  )
}

function NavBar_pr() {
  const wishItems = useSelector((state) => state.wishList)
  const totalWishCount = useSelector((state) => state.wishCounti.totalWishCount)
  const navigate = useNavigate()
  // const { totalWishCount} = useContext(Contexti)
  return (
    <>
      <Navbar className='navbar'  expand="sm"  collapseOnSelect={true}>
        <Container>
        <Navbar.Brand className='navbrand' onClick={() => navigate("/")}>BixWie</Navbar.Brand>
        
        <CartIconi classyName="navilinkoMain ms-auto cartIconPhone"/>
        <Navbar.Toggle style={{border: "none", color: 'transparent'}}  >
          <List weight='fill' fill='black' size={32}/>
        </Navbar.Toggle>


        <Navbar.Collapse>
          <Nav className='ms-auto' >
          <Nav.Link href='#' className='mr-4 navilinkoMain '><Link to="/" className='navilinko'>Home</Link></Nav.Link>
          <Nav.Link href='#' className='mr-4 navilinkoMain '><Link to="/wishlist" className='navilinko'>Wishlist {totalWishCount > 0 && <>({totalWishCount})</>}</Link></Nav.Link>
          
          <CartIconi classyName="navilinkoMain cartIconBig" />
            
          </Nav>
            
        </Navbar.Collapse>
        </Container>
        
      </Navbar>
    </>
  )
}

export default NavBar_pr
